<template>
  <module
    ref="module"
    id="polices"
    title-icon="fa fa-book"
    :title="$t('userpolicies')"
    :use-default-list="false"
  >
    <div slot="global" class="row">
      <div class="col-md-10 mx-auto">

        <card type="plain" class="card-subcategories">
          <template slot="header">
            <h3 class="card-title text-center">{{ $t('userpolicies') }}</h3>
          </template>

          <p class="lead">
            {{ $t('policiesintro') }}
          </p>

          <p class="text-center">
            <p-button type="primary" v-on:click="startDownload" size="lg">
              <i slot="label" class="fa fa-cloud-download"></i>
              <span v-if="!wlSession">
                {{ $t('downloadtxt') }}
              </span>
              <span v-if="wlSession">
                {{ $t('hkopolicies') }}
              </span>
            </p-button>

            <p-button v-if="wlSession"
                      type="primary"
                      v-on:click="startDownloadWl"
                      size="lg">
              <i slot="label" class="fa fa-cloud-download"></i>
              <span>
                {{ $t('wlpolicies', [whitelabel]) }}
              </span>
            </p-button>

          </p>
          <template slot="footer">
            <disclaimer v-if="wlSession" :disclaimer-content="disclaimer" font-color="black"> </disclaimer>
          </template>
        </card>
      </div>
    </div>

  </module>
</template>
<script>
import {Tabs, TabPane, Card} from 'src/components/UIComponents'
import Constants from 'src/assets/constants'
import Disclaimer from "@/components/Dashboard/Views/Pages/Layout/Disclaimer.vue";
const rules = Constants.methods.getRules()
export default {
  components: {
    Disclaimer,
    TabPane,
    Tabs,
    Card
  },
  data () {
    return {
      wlSession: rules.isWhitelabelSession,
      whitelabel: rules.whitelabel,
      disclaimer: rules.disclaimer
    }
  },
  methods: {
    startDownload() {
      var request = new XMLHttpRequest();

      const stateChanged = e => {
        if(request.readyState == 2 && request.status == 200) {
          //console.log('Download Started');
        }
        else if(request.readyState == 3) {
          //console.log("In Progress");
        }
        else if(request.readyState == 4) {
          //console.log("Download Done");
          window.open('/static/docs/HokoCloudDocs.zip', '_top');
          //this.dlall = true;
        }
      };

      request.addEventListener('readystatechange', stateChanged);

      request.addEventListener('progress', function(e) {
        var percent_complete = (e.loaded / e.total)*100;
        //console.log(percent_complete);
      });

      request.responseType = 'blob';

      // Downloading Doc
      request.open('get', '/static/docs/HokoCloudDocs.zip');
      request.send();
    },
    startDownloadWl() {
      const reject = () => {
        this.$toast.warning('File not found');
      };
      this.$downloadPolicies(rules.whitelabel, rules.policies, reject)
    }
  }
}

</script>
<style>
.footnote {
  font-size: 8px;
}
</style>
